import SpecCustomField from './SpecCustomField';

export default class SpecCustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      onCustomFieldSave: this.onCustomFieldSave.bind(this),
      onCustomFieldCancel: this.onCustomFieldCancel.bind(this),
      onCustomFieldChange: this.onCustomFieldChange.bind(this),
      toggleCustomFieldEditMode: this.toggleCustomFieldEditMode.bind(this),
      customFieldEditMode: false,
      customFieldValue: null,
      contactAttributes: {},
      errors: []
    };
  }

  onCustomFieldSave(e, customField) {
    const { customFieldValue, contactAttributes } = this.state;
    const { report, reportVersion } = this.props;
    const url = customField.id
      ? `/institution/reports/${report.id}/versions/${reportVersion.id}/custom_fields/${customField.id}`
      : `/institution/reports/${report.id}/versions/${reportVersion.id}/custom_fields/`;

    const method = customField.id
      ? 'PATCH'
      : 'POST';

    const data = {
      custom_field: {
        id: customField.id,
        custom_field_definition_id: customField.customFieldDefinitionId,
        model_id: customField.modelId,
        mode_type: customField.modelType,
        value: customFieldValue,
        contact_attributes: contactAttributes
      }
    };

    let { customFields } = this.state;

    $j.ajax({
      url,
      method,
      data,
      success: (data) => {
        if (data.errors) {
          customFields = customFields.map((cf) => {
            if (cf.customFieldDefinition.id === customField.customFieldDefinitionId)
              cf.errors = data.errors.join(', ');

            return cf;
          });

          this.setState({
            customFields
          });
        } else {
          customFields = customFields.map(cf =>
            (cf.customFieldDefinition.id === data.customField?.customFieldDefinition?.id ? data.customField : cf));

          this.setState({
            customFields,
            customFieldEditMode: false
          });
        }
      }
    }).error((xhr) => {
      if (xhr.responseJSON.errors)
        this.setState({ errors: xhr.responseJSON.errors });
      else
        location.reload();
    });
  }

  onCustomFieldChange(e, type, valuesArr = [], contactData = {}) {
    if (type === 'multiselect') {
      this.setState({
        customFieldValue: valuesArr.join('¦')
      });
    } else if (type === 'contact') {
      this.setState({
        contactAttributes: contactData
      });
    } else {
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState({
        customFieldValue: value
      });
    }
  }

  onCustomFieldCancel(e) {
    e.preventDefault();
    this.setState({
      customFieldEditMode: false
    });
  }

  toggleCustomFieldEditMode(e, customField) {
    e.preventDefault();
    this.setState({
      customFieldValue: customField.customFieldDefinition.fieldType === 'dropdown' ? customField.value : customField.displayValue,
      customFieldEditMode: customField.customFieldDefinition.id
    });
  }

  sortCustomFields() {
    const { customFields } = this.state;
    const { customFieldDefinitionIds } = this.props;
    if (customFieldDefinitionIds.length > 0)
      return customFieldDefinitionIds.map(id => customFields.find(cf => cf.customFieldDefinitionId === id));

    return null;
  }

  renderCustomFields() {
    const { customFieldDefinitionIds, name, allOptions, institution, helpPoints } = this.props;
    let { errors } = this.state;
    const sortedCustomFields = this.sortCustomFields();
    if (sortedCustomFields) {
      return (
        sortedCustomFields.map((customField, fieldIndex) =>
          customFieldDefinitionIds.includes(customField.customFieldDefinitionId) && (
          <SpecCustomField
            key={`cf-${fieldIndex}`}
            childKey={`cf-${fieldIndex}`}
            customField={customField}
            {...this.state}
            name={name}
            allOptions={allOptions}
            institution={institution}
            helpPoints={helpPoints}
            index={fieldIndex}
            errors={errors}
          />
          ))
      );
    }
    return '';
  }

  render() {
    return (
      <div className="span-24">
        {this.renderCustomFields()}
      </div>
    );
  }
}
