import React from 'react';
import Select, { components } from 'react-select';
import HelpPoint from '../../Helpers/HelpPoint';

const DropdownIndicator = () => (
  <a
    tabIndex="-1"
    title="Show All Tools Options"
    className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right optimized-toggle"
    role="button"
    data-testid="primarySelectIcon"
  >
    <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Tools Options" />
    <span className="ui-button-text" />
  </a>
);

class PrimaryToolForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.prepareData(props.dataTools),
      selectedOption: this.prepareData(props.selectedPrimaryTool?.dataTool),
      openMenu: false,
      primaryNote: props.selectedPrimaryTool ? props.selectedPrimaryTool.specToolNote : '',
      externalId: props.selectedPrimaryTool ? props.selectedPrimaryTool.specToolRefId : '',
    };
  }

  componentDidUpdate(_prevProps) {
    if (_prevProps.dataTools != this.props.dataTools) {
      const options = this.prepareData(this.props.dataTools);
      this.setState({
        options
      });
    }
  }

  prepareData(data) {
    if (!data) return null;

    return Array.isArray(data) ? data.map(this.serializeOption)
      : this.serializeOption(data);
  }

  serializeOption(option) {
    return { value: option.id, label: option.name };
  }

  handleChange = (selectedOption) => {
    const { onChangeCallback } = this.props;
    onChangeCallback(selectedOption);
    this.setState({ selectedOption });
  };

  filterOption = (option, searchText) => {
    const { label } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  handleInputChange = (query, { action }) => {
    if (action === 'input-change' && query.length >= 1)
      this.setState({ openMenu: true });
    else
      this.setState({ openMenu: false });
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  onSelectorChange = (e) => {
    this.handleChange(e);
    this.hideMenu();
  }

  onChangePrimaryNote = (e) => {
    this.setState({
      primaryNote: e.target.value
    });
  }

  onChangeExternalId = (e) => {
    let externalIdInput = document.getElementById('spec_tool_ref_id');
    if(externalIdInput.className.includes('copied-name')) {
      externalIdInput.className = externalIdInput.className.replace('copied-name', '');
    }

    this.setState({
      externalId: e.target.value
    });
  }

  onRemovePrimaryTool = () => {
    const { onChangeCallback } = this.props;
    onChangeCallback(null);

    this.setState({
      selectedOption: null,
      externalId: '',
      primaryNote: ''
    });
  }

  onSpecNameCopy = () => {
    const { externalId } = this.state;
    const specName = this.props.reportCreation ? document.getElementById('name')?.value : document.getElementById('report_version_name')?.value;

    const externalIdInput = document.getElementById('spec_tool_ref_id');
    externalIdInput.className = 'copied-name';

    const externalIdValue = externalId ? `${externalId}${specName}` : `${specName}`;
    this.setState({
      externalId: externalIdValue
    });
  }

  render() {
    const {
      selectedOption,
      options,
      externalId,
      primaryNote,
    } = this.state;

    const { removeIconSrc, helpPoints } = this.props;
    const emptyForm = !selectedOption && !externalId && !primaryNote;
    const primaryToolFieldName = this.props.reportCreation ? 'report[version_attributes][report_version_tools_associations_attributes][0]' : 'report_version[report_version_tools_associations_attributes][0]';
    const removeBtn = selectedOption || externalId || primaryNote;
    const controlStyle = {
      control: base => ({
        ...base, height: 28, minHeight: 28, maxHeight: 28
      }),
      menu: base => ({
        ...base, minWidth: 213, width: 'max-content'
      }),
      option: (base) => ({
        ...base, height: '32px', lineHeight: '32px', padding: '0 16px'
      })
    };

    return (
      <div className="edit_primary_tool_wrap">
        <div className="primary-tool-select-container tools-optimizes-select">
          <label className='primary-tool-label' htmlFor="primaryTool">
            <h4>Primary Tool<HelpPoint helpPoint={helpPoints.point1} /></h4>
          </label>
          <div data-testid="primarySelect" className="optimizedSelectWrap margin-top-5px">
            <Select
              inputId="primaryTool"
              name={emptyForm ? '' : `${primaryToolFieldName}[data_tool_id]`}
              value={selectedOption}
              onChange={this.handleChange}
              maxMenuHeight={200}
              options={options}
              filterOption={this.filterOption}
              components={{ DropdownIndicator }}
              placeholder="Type or Select..."
              styles={controlStyle}
              className="OptimizedSelect"
              classNamePrefix="OptimizedSelect"
            />
          </div>
        </div>
        <div className="external-id-input-container" >
          <h4>External Tool ID<HelpPoint helpPoint={helpPoints.point2} /></h4>
          <input
            id='spec_tool_ref_id'
            data-testid="externalIdInput"
            type="text"
            name={emptyForm ? '' : `${primaryToolFieldName}[spec_tool_ref_id]`}
            value={externalId}
            onChange={this.onChangeExternalId}
          />
        </div>
        <div className="copy-spec-name-btn-container">
          <input
            disabled={externalId ? true : false}
            data-testid="copyBtn"
            name="copy-button"
            type="button"
            title="copy-ext-id-btn"
            value="Copy Spec Name"
            className='copy-spec-name-btn'
            onClick={this.onSpecNameCopy}
          />
          <HelpPoint helpPoint={helpPoints.point6} />
        </div>
        <div className="tool-note-input-container">
          <h4>Usage Note<HelpPoint helpPoint={helpPoints.point3} /></h4>
          <textarea
            data-testid="usageNote"
            className="tool-note-input"
            type="text"
            name={emptyForm ? '' : `${primaryToolFieldName}[spec_tool_note]`}
            value={primaryNote}
            onChange={this.onChangePrimaryNote}
            rows={1}
          />
          <input
            type="hidden"
            className="item-value"
            name={emptyForm ? '' : `${primaryToolFieldName}[primary_tool]`}
            value
          />
        </div>
        <div className="span-2 btn-remove-item-container primary-tool-remove-container">
          {removeBtn && <button
            data-testid="removeBtn"
            name="button"
            type="button"
            className="btn-remove-item"
            aria-label="remove item multiselect item"
            onClick={this.onRemovePrimaryTool}
          >
            <img
              src={removeIconSrc}
              alt="Remove field name"
              tabIndex="0"
            />
          </button> }
        </div>
      </div>
    );
  }
}

export default PrimaryToolForm;
