import React from 'react';

const SelectWikiLinkReportModal = (props) => {
  const { modalMessages } = props;

  return (
    <div className="wiki-link-report-modal hide">
      <p className="hide header-text-many-opts">{modalMessages.specNameNotFoundManyOpts}</p>
      <p className="hide header-text-name-changed">{modalMessages.specNameChanged}</p>
      <p className="hide header-text-tool-changed">{modalMessages.specToolChanged}</p>
      <p className="hide header-text-tool-name-changed">{modalMessages.specLinkedToolNameChanged}</p>
      <p className="hide header-text-name-and-tool-changed">{modalMessages.specNameAndToolChanged}</p>
      <p className="hide header-text-one-opt">{modalMessages.specNameNotFoundOneOpt}</p>
      <p className="hide header-text-not-unique">{modalMessages.specNameNotUnique}</p>
      <br />
      <ul className="report-options-list" />
      <br />
      <p className="hide info-text-many-opts">{modalMessages.infoManyOpts}</p>
      <p className="hide info-text-one-opt">{modalMessages.infoOneOpt}</p>
      <p className="hide info-text-not-unique-many-opts">{modalMessages.infoNameNotUniqueManyOpts}</p>
      <p className="hide info-text-not-unique">{modalMessages.infoNameNotUnique}</p>
      <div className="float-right">
        <a className="pretty-button create-link-btn wiki-modal-btn disabled-btn" disabled title={modalMessages.createLinkBtn} >{modalMessages.createLinkBtn}</a>
        <a className="pretty-button create-missing-spec-btn wiki-modal-btn" title={modalMessages.createMissingSpecBtn} >{modalMessages.createMissingSpecBtn}</a>
        <a className="pretty-button cancel-wiki-select-btn wiki-modal-btn" title={modalMessages.cancelBtn} >{modalMessages.cancelBtn}</a>
      </div>
    </div>
  );
};

export default SelectWikiLinkReportModal;
