import DataMappingControlComponent from "./DataMappings/DataMappingControlComponent";

export default class DataMappingControlContainer extends React.Component {
  constructor(props) {
    super(props)
    this.getDataSchemaObject = this.getDataSchemaObject.bind(this),
    this.createSourceDataSystem = this.createSourceDataSystem.bind(this),
    this.deleteSourceDataSystem = this.deleteSourceDataSystem.bind(this),
    window.removeSourceDataSystemFromStateModal = this.removeSourceDataSystemFromState.bind(this),
    window.addSourceDataSystemFromStateModal = this.addSourceDataSystemFromState.bind(this)
    this.state = {
      ...props,
      editable: props.editable,
      targetSystem: props.targetErpSystem,

      openNewProcessingStepForm: this.openNewProcessingStepForm.bind(this),
      openEditProcessingStepForm: this.openEditProcessingStepForm.bind(this),
      openEditSourceForm: this.openEditSourceForm.bind(this),
      onChange: this.onChange.bind(this),
      onCancel: this.onCancel.bind(this),
      onSave: this.onSave.bind(this),
      openNewLineageForm: this.openNewLineageForm.bind(this),
      openEditLineageForm: this.openEditLineageForm.bind(this),
      onEditNode: this.onEditNode.bind(this),
      onShowNode: this.onShowNode.bind(this),
      onLineageCreate: this.onLineageCreate.bind(this),
      onDelete: this.onDelete.bind(this),
      onLineageUpdate: this.onLineageUpdate.bind(this),
      onLineageChange: this.onLineageChange.bind(this),
      openNewSourceForm: this.openNewSourceForm.bind(this),
      onOrderSubmit: this.onOrderSubmit.bind(this),
      onOrderChange: this.onOrderChange.bind(this),
      onLineageDelete: this.onLineageDelete.bind(this),
      onLineageDeleteMessage: this.onLineageDeleteMessage.bind(this),
      onDropSchemaObjectToSource: this.onDropSchemaObjectToSource.bind(this),
      onDropTermToSource: this.onDropTermToSource.bind(this),
      onDropSchemaObjectToLineage: this.onDropSchemaObjectToLineage.bind(this),
      onDropTermToLineage: this.onDropTermToLineage.bind(this),
      toggleTargetSystemEditMode: this.toggleTargetSystemEditMode.bind(this),
      onTargetSystemChange: this.onTargetSystemChange.bind(this),
      onTargetSystemSave: this.onTargetSystemSave.bind(this),
      onTargetSystemCancel: this.onTargetSystemCancel.bind(this),
      onTargetSystemRemove: this.onTargetSystemRemove.bind(this),
      toggleSourceSystemsEditMode: this.toggleSourceSystemsEditMode.bind(this),
      getSourceSystemsEditMode: this.getSourceSystemsEditMode.bind(this),
      onSourceSystemAdd: this.onSourceSystemAdd.bind(this),
      onSourceSystemRemove: this.onSourceSystemRemove.bind(this),
      toggleEditable: this.toggleEditable.bind(this),
      resetReportItem: this.resetReportItem.bind(this),
      onReportItemCreate: this.onReportItemCreate.bind(this),
      setReportItemLineageTargetId: this.setReportItemLineageTargetId.bind(this),
      updateReportItems: this.updateReportItems.bind(this),
      detailsLabel: 'Mapping Description'
    }
  }

  openShowSource(source, lineage) {
    this.setState({sourceShow: source})
  }

  openNewSourceForm(source) {
    this.setState({source})
  }

  openEditSourceForm(source, lineage) {
    source = this.buildEditSource(source, lineage);

    this.setState({source})
  }

  openShowLineage(target, lineage) {
    this.setState({ targetShow: target });
  }

  openEditLineageForm(_target, _lineage) {
    const [lineage, lineageTarget] = this.buildEditLineage(_target, _lineage);
    this.setState({ lineage, lineageTarget });
  }

  openShowProcessingStep(processingStep, lineage) {
    this.setState({processingStepShow: processingStep})
  }

  openNewProcessingStepForm(from, to, lineage) {
    let processingStep = this.buildNewProcessingStep(from, to, lineage)

    this.setState({processingStep})
  }

  openNewLineageForm(e) {
    e.preventDefault();
    const lineage = this.buildNewLineage();
    const { sourceErpSystems } = this.state;
    let erpSystemId;
    if (sourceErpSystems.length === 1) {
      erpSystemId = sourceErpSystems[0].id;
    }
    const reportItem = this.buildNewReportItem();
    const lineageTarget = {
      erpSystemId,
      reportItem
    };
    this.setState({ lineage, reportItem, lineageTarget });
  }

  openEditProcessingStepForm(processingStep, lineage) {
    processingStep = this.buildEditProcessingStep(processingStep, lineage);

    this.setState({processingStep});
  }

  buildNewProcessingStep(from, to, lineage) {
    return {
      target_object_id: to.id,
      target_object_type: to.type,
      from_object_id: from.id,
      from_object_type: from.type,
      related_object_id: lineage.relatedObjectId,
      related_object_type: lineage.relatedObjectType,
      note: '',
      processing_type_id: ''
    };
  }

  buildEditProcessingStep(processingStep, lineage) {
    return {...processingStep,
      processing_type_id: processingStep.processingTypeId,
      related_object_id: lineage.relatedObjectId,
      related_object_type: lineage.relatedObjectType
    }
  }

  buildEditSource(source, lineage) {
    return { ...source,
      lineageId: lineage.id,
    };
  }

  buildNewLineage() {
    return {
      related_object_id: this.props.primaryObjectId,
      related_object_type: this.props.primaryObjectType,
    };
  }

  buildNewReportItem() {
    return {
      reportVersionId: (this.props.reportVersion && this.props.reportVersion.id) ||
        this.props.primaryObjectId
    };
  }

  buildEditLineage(target, lineage) {
    return [
      { ...lineage },
      {
        ...target,
        object_label: target.name,
        related_object_id: target.relatedObjectId,
        related_object_type: target.relatedObjectType,
        erp_system_id: target.erpSystemId
      }
    ];
  }

  onChange(e) {
    const processingStep = {
      ...this.state.processingStep,
      [e.target.name]: e.target.value
    };

    this.setState({processingStep})
  }

  onCancel(e) {
    e && e.preventDefault();
    this.resetProcessingStep();
    this.resetLineage();
    this.resetSource();
    this.resetReportItem();
    this.resetShow();
  }

  resetProcessingStep() {
    this.setState({processingStep: null})
  }

  resetLineage() {
    this.setState({ lineage: null, lineage_target: null });
  }

  resetReportItem() {
    this.setState({ report_item: null });
  }

  resetSource() {
    this.setState({ source: null });
  }

  resetShow() {
    this.setState({
      sourceShow: null,
      targetShow: null,
      processingStepShow: null
    });
  }

  onSave(e) {
    e.preventDefault();
    const { id } = this.state.processingStep;
    const url = id ? `/institution/linio_processings/${id}` : '/institution/linio_processings';
    const method = id ? 'PUT' : 'POST';
    $j.ajax({
      url: url,
      method: method,
      data: { linio_processing: this.state.processingStep },
      success: (data, status, xhr) => {
        this.resetProcessingStep();
        this.setState({ lineages: data.data.lineages });
      }
    });
  }

  onLineageCreate(data) {
    this.setReportItemLineageTargetId(data.treeJson.reportItemId, data.treeJson.id);
    this.setState({ lineages: [...this.state.lineages, data] });
  }

  onReportItemCreate(data) {
    this.setState({ reportItems: [...this.state.reportItems, data] });
  }

  onEditNode(node, lineage) {
    const isNodeReadOnly = !this.state.editable ||
      (node.fromTechnicalDefinition && lineage.relatedObjectType === 'Report::Version');
    if (isNodeReadOnly) {
      this.onShowNode(node, lineage);
      return;
    }
    if (node.type === 'LinioProcessing') {
      this.openEditProcessingStepForm(node, lineage);
    } else if (node.type === 'LinioSource') {
      this.openEditSourceForm(node, lineage);
    } else if (node.type === 'LineageTarget') {
      this.openEditLineageForm(node, lineage);
    }
  }

  onShowNode(node, lineage) {
    if (node.type === 'LinioProcessing'){
      this.openShowProcessingStep(node, lineage)
    } else if (node.type === 'LinioSource') {
      this.openShowSource(node, lineage)
    } else if (node.type === 'LineageTarget') {
      this.openShowLineage(node, lineage)
    }
  }

  onDelete(node, lineage) {
    if (node.canBeDeleted) {
      if (node.type === 'LinioProcessing') {
        jConfirm('Delete Processing?', `Are you sure you want to delete this processing: ${node.name}?`, () => {
          this.deleteProcessing(node, lineage);
        });
      } else {
        jConfirm('Delete Source Item?', `Are you sure you want to delete this source item: ${node.name}?`, () => {
          this.deleteSource(node, lineage);
        });
      }
    } else {
      if (node.type === 'LinioProcessing') {
        jAlert('Error', this.props.cannotDeleteProcessingMsg);
      } else {
        jAlert('Error', this.props.cannotDeleteSourceMsg);
      }
    }
  }

  deleteProcessing(node, lineage) {
    const data = {
      linio_processing: {
        related_object_id: lineage.relatedObjectId,
        related_object_type: lineage.relatedObjectType,
      }
    };

    $j.ajax({
      url: `/institution/linio_processings/${node.id}`,
      method: 'DELETE',
      data,
      success: (data, status, xhr) => {
        this.setState({ lineages: data.data.lineages });
      }
    });
  }

  deleteSource(node, lineage) {
    const data = {
      linio_source: {
        related_object_id: lineage.relatedObjectId,
        related_object_type: lineage.relatedObjectType,
      }
    };

    $j.ajax({
      url: `/institution/linio_sources/${node.id}`,
      method: 'DELETE',
      data,
      success: (data, status, xhr) => {
        this.setState({ lineages: data.data.lineages });
      }
    });
  }

  onLineageUpdate(lineageId, reportItem) {
    $j.ajax({
      url: `/institution/lineages/${lineageId}`,
      method: 'GET',
      success: (data) => {
        if (reportItem) {
          reportItem.lineageTargetId = data.treeJson.id;
          reportItem.id = data.treeJson.reportItemId;
          this.updateReportItems(data.treeJson.id, reportItem);
        }
        const newLineages = this.state.lineages.map((lineage) => {
          return data.id === lineage.id ? data : lineage;
        });
        this.setState({ lineages: newLineages });
      }
    }).error(() => {
      location.reload();
    });
  }

  onLineageChange(lineage) {
    this.setState({ lineages: [lineage] });
  }

  onOrderSubmit(e, lineage) {
    e && e.preventDefault();

    const { order, newOrder } = lineage;

    if (!+newOrder || order === +newOrder) {
      return;
    }

    $j.ajax({
      url: `/institution/lineage_orderings/${lineage.id}`,
      method: 'PATCH',
      data: { order: +newOrder },
      success: (data, _status, _xhr) => {
        this.setState({ lineages: data.data.lineages });
      }
    });
  }

  onOrderChange(e, lineage) {
    const newLineages = this.state.lineages.map((l) => {
      if (l.id === lineage.id) {
        return {
          ...l,
          newOrder: e.target.value
        };
      }
      return l;
    });

    this.setState({ lineages: newLineages });
  }

  onLineageDelete(e, lineage) {
    e.preventDefault();
    jConfirm('Are you sure?', this.onLineageDeleteMessage(e, lineage), () => {
      $j.ajax({
        url: `/institution/lineages/${lineage.id}`,
        method: 'DELETE',
        success: (data, _status, _xhr) => {
          if (lineage.treeJson.relatedObjectType === 'Term' && lineage.treeJson.relatedObjectId) {
            const term = this.state.terms.find(term => term.id == lineage.treeJson.relatedObjectId);
            term.technicalRelationships = [];
          }
          if (data.data.lineages.length < 1) {
            this.hideExportButton();
            this.hideDeleteAllButton();
          }
          this.setReportItemLineageTargetId(lineage.treeJson.reportItemId, null);
          this.setState({ lineages: data.data.lineages });
        }
      });
    }, 'Yes');
  }

  hideDeleteAllButton() {
    const delAllBtn = $j('.delete-lineages-container');
    delAllBtn.addClass('hide');
  }

  hideExportButton() {
    let exportBtn = $j('.export-lineages');
    exportBtn.addClass('hide');
  }

  onLineageDeleteMessage(e, lineage) {
    const { reportItem } = lineage.treeJson;
    const usedTabs = ['Data Items'];
    if (this.props.reportTemplate == 'ETL') {
      return 'Are you sure you want to delete this lineage?'
    }
    if (lineage.relatedObjectType === 'TechnicalDefinition') {
      return 'Are you sure you want to delete this linio?';
    }
    if (reportItem && reportItem.reportDisplayItem) {
      usedTabs.push('Display');
    }
    if (reportItem && reportItem.reportFilterCriteria.length > 0) {
      usedTabs.push('Selections');
    }
    if (reportItem && reportItem.reportSortCriteria.length > 0) {
      usedTabs.push('Sort Criteria');
    }
    return `Warning: Deleting this lineage will also remove the Data Item from the following places: ${usedTabs.join(', ')}. `;
  }

  getPrimaryDefinition(dataModelObject) {
    const { terms } = this.props;
    if (dataModelObject.related_term && dataModelObject.related_term.dcb_object_id) {
      const TERM_ID = dataModelObject.related_term.dcb_object_id;
      return terms.find(term => term.id === TERM_ID);
    }
    return null;
  }

  onDropSchemaObjectToSource(e, lineage, sourceParams) {
    e.preventDefault();
    let schemaObject = JSON.parse(JSON.parse(e.dataTransfer.getData('text')).schemaObject);
    if (schemaObject.class_name !== 'Column') return;
    schemaObject = this.getDataSchemaObject(schemaObject);
    const RELATED_TERM = this.getPrimaryDefinition(schemaObject);
    const source = {
      ...sourceParams,
      relatedObjectType: `DataModel${schemaObject.className}`,
      relatedObjectId: schemaObject.id,
      relatedObject: schemaObject,
      lineageId: lineage.id,
      objectLabel: schemaObject.name
    };
    if (RELATED_TERM) {
      source.termId = RELATED_TERM.id;
    }
    let selectedElement = schemaObject.selectedElement;

    if (!(selectedElement && selectedElement === 'Target Erp System'))
      this.setState({ source });
  }

  onDropTermToSource(termId, lineage, sourceParams) {
    const term = this.state.terms.find(term => term.id === termId);

    const source = {
      ...sourceParams,
      termId: term.id,
      termName: term.name,
      lineageId: lineage.id,
      objectLabel: term.name
    };

    this.setState({source})
  }

  getDataSchemaObject(schemaObject) {
    let changedSchemaObject;
    if (schemaObject.class_name === 'Column' || schemaObject.class_name === 'Index' ) {
      changedSchemaObject =
        {...schemaObject,
          className: schemaObject.class_name,
          parentObject: {
            dataSchemaVersion:
              {
                dataSchema:
                  {
                    id: schemaObject.parent_object.data_schema_version.data_schema.id,
                    erpSystem:
                      {
                        id: schemaObject.parent_object.data_schema_version.data_schema.erp_system.id
                      }
                  }
              }
          },
          relatedTerm: schemaObject.related_term
        }
    } else {
      changedSchemaObject =
        {...schemaObject,
          className: schemaObject.class_name,
          parentObject: {
            dataSchemaVersion:
              {
                dataSchema:
                  {
                    id: schemaObject.parent_object.data_schema_version.data_schema.id,
                    erpSystem:
                      {
                        id: schemaObject.data_schema_version.data_schema.erp_system.id
                      }
                  }
              }
          },
          dataModelObjectId: schemaObject.id,
          relatedTerm: schemaObject.related_term
        }
    }
    return changedSchemaObject
  }

  onDropSchemaObjectToLineage(e) {
    e.preventDefault();
    DCB.html5droppableHighlight.drop();
    let schemaObject = JSON.parse(JSON.parse(e.dataTransfer.getData('text')).schemaObject);
    const { sourceErpSystems } = this.state;
    let erpSystemId;
    if (sourceErpSystems.length === 1) {
      erpSystemId = sourceErpSystems[0].id;
    }
    schemaObject = this.getDataSchemaObject(schemaObject);
    const lineage = this.buildNewLineage();
    const reportItem = this.buildNewReportItem();
    reportItem.dataModelObjectType = `DataModel${schemaObject.className}`;
    reportItem.dataModelObjectId = schemaObject.id;
    reportItem.objectLabel = schemaObject.name;
    reportItem.dataModelObject = schemaObject;
    reportItem.erpSystem = schemaObject.parentObject.dataSchemaVersion.dataSchema.erpSystem;
    reportItem.erpSystemId = schemaObject.parentObject.dataSchemaVersion.dataSchema.erpSystem.id;
    const RELATED_TERM = this.getPrimaryDefinition(schemaObject);
    if (RELATED_TERM) {
      reportItem.termId = RELATED_TERM.id;
    }

    const lineageTarget = {
      reportItem,
      erpSystemId,
    };

    const { selectedElement } = schemaObject;

    if (!(selectedElement && selectedElement === 'Source Erp System')) {
      this.setState({ lineage, lineageTarget, reportItem });
    }
  }

  onDropTermToLineage(termId) {
    const { sourceErpSystems } = this.state;
    let erpSystemId;
    if (sourceErpSystems.length === 1) {
      erpSystemId = sourceErpSystems[0].id;
    }
    const term = this.state.terms.find(trm => trm.id === termId);
    const lineage = this.buildNewLineage();

    const reportItem = this.buildNewReportItem();
    reportItem.objectLabel = term.name;
    reportItem.termId = term.id;
    reportItem.term = term;

    const lineageTarget = {
      objectLabel: term.name,
      reportItem,
      erpSystemId,
    };

    this.setState({ lineage, lineageTarget });
  }

  toggleTargetSystemEditMode(e) {
    e && e.preventDefault();
    this.setState({ targetSystemEditMode: !this.state.targetSystemEditMode });
  }

  toggleEditable(e) {
    e && e.preventDefault()
    this.setState({
      editable: !this.state.editable
    })
  }

  onTargetSystemChange(e) {
    const targetErpSystem = e.target ? this.findErpSystemById(e.target.value) : this.findErpSystemById(e.value);
    this.setState({targetErpSystem})
  }

  findErpSystemById(id) {
    const { erpSystems } = this.state

    return erpSystems.
      filter(erpSystem => erpSystem.groupFlag).
      reduce((memo, obj) =>
        memo.concat(obj.childSystems), []
      ).concat(erpSystems.filter(erpSystem => !erpSystem.groupFlag)).
      find(erpSystem => erpSystem.id === +id)
  }

  onTargetSystemSave(selectedTargetErpSystem) {
    const targetErpSystem = this.findErpSystemById(selectedTargetErpSystem.value);
    const { primaryId, primaryObjectId } = this.state;

    this.setState({
      targetSystemEditMode: false,
      targetErpSystem,
      targetSystem: targetErpSystem
    });

    const data = {
      report_version: {
        target_erp_system_id: targetErpSystem ? targetErpSystem.id : ''
      }
    };

    $j.ajax({
      url: `/institution/reports/${primaryId}` +
        `/versions/${primaryObjectId}/target_erp_system`,
      method: 'PATCH',
      data,
    });
  }

  onTargetSystemCancel(e) {
    e.preventDefault();
    this.setState({
      targetErpSystem: this.state.targetSystem,
      targetSystemEditMode: false
    });
  }

  onTargetSystemRemove(e) {
    e.preventDefault();

    const { primaryId, primaryObjectId } = this.state;

    this.setState({
      targetSystemEditMode: false,
      targetErpSystem: null,
      targetSystem: null,
    });

    const data = {
      report_version: {
        target_erp_system_id: ''
      }
    };

    $j.ajax({
      url: `/institution/reports/${primaryId}` +
      `/versions/${primaryObjectId}/target_erp_system`,
      method: 'PATCH',
      data,
    });
  }

  toggleSourceSystemsEditMode(e) {
    e && e.preventDefault()
    this.setState({
      sourceSystemsEditMode: !this.state.sourceSystemsEditMode,
    });
  }

  getSourceSystemsEditMode() {
    return !!this.state.sourceSystemsEditMode;
  }

  onSourceSystemAdd(value) {
    const { sourceErpSystems } = this.state;
    const sourceErpSystem = this.findErpSystemById(value);

    if (sourceErpSystem) {
      this.setState({
        sourceErpSystems: sourceErpSystems.concat(sourceErpSystem)
      });

      let url = this.props.sourceErpSystemsPath;
      $j.ajax({
        url: url,
        method: 'POST',
        data: { source_erp_system_id: sourceErpSystem.id },
        success: data => {
          this.createSourceDataSystem(data, url, sourceErpSystem.id)
        }
      })
    }
  }

  createSourceDataSystem(data, sourceErpSystemPath, sourceErpSystemId) {
    if (data.workflow_definition_version) {
      var WorkflowDVsHTML = ChangeWorkflowDefinitionVersion.setModalBoxHtml(data);
      var html = "Add this data system and restart the workflow?<hr>" +
        "This change affects which workflow applies.  If you add this data system, " + WorkflowDVsHTML + "<br>" +
        "<input type='button' value='Yes' class='modalbox-btn' data-erp-system-id="+ sourceErpSystemId +" data-url="+ sourceErpSystemPath +" onclick='ChangeWorkflowDefinitionVersion.addReportSourceDataSystem()'/>" +
        "<input type='button' value='No' " +
        "class='modalbox-btn modalbox-btn_negative' " +
        "onclick='ChangeWorkflowDefinitionVersion.removeSourceDataSystemFromStateModalBox(this)' />";
      var title = 'Warning: Changing Workflow';

      ChangeWorkflowDefinitionVersion.showModalBox(html, title);
      return false;
    } else if (data.flash_notice){
      location.reload();
    }
  }

  deleteSourceDataSystem(data, sourceErpSystemPath, sourceErpSystemId) {
    if (data.workflow_definition_version) {
      const WorkflowDVsHTML = ChangeWorkflowDefinitionVersion.setModalBoxHtml(data);
      const html = "Remove this data system and restart the workflow?<hr>" +
        "This change affects which workflow applies.  If you remove this data system, " + WorkflowDVsHTML + "<br>" +
        "<input type='button' value='Yes' class='modalbox-btn' data-erp-system-id="+ sourceErpSystemId +" data-url="+ sourceErpSystemPath +" onclick='ChangeWorkflowDefinitionVersion.removeReportSourceDataSystem()'/>" +
        "<input type='button' value='No' " +
        "class='modalbox-btn modalbox-btn_negative' " +
        "onclick='ChangeWorkflowDefinitionVersion.addSourceDataSystemFromStateModalBox(this)' />";
      const title = 'Warning: Changing Workflow';

      ChangeWorkflowDefinitionVersion.showModalBox(html, title);
      return false;
    } else if (data.flash_notice) {
      location.reload();
    }
  }

  removeSourceDataSystemFromState() {
    const { sourceErpSystems } = this.state;

    sourceErpSystems.pop();

    this.setState({
      sourceErpSystems,
    });

    Modalbox.hide();
  }

  addSourceDataSystemFromState() {
    const { sourceErpSystems } = this.state;
    const value = $j('.modalbox-btn').first().data('erp-system-id');
    const sourceErpSystem = this.findErpSystemById(value);

    if (sourceErpSystem) {
      this.setState({
        sourceErpSystems: sourceErpSystems.concat(sourceErpSystem)
      });
    }

    Modalbox.hide();
  }

  onSourceSystemRemove(e, id) {
    e.preventDefault();
    const that = this;
    const url = this.props.sourceErpSystemsPath;
    const { sourceErpSystems, lineages } = this.state;

    this.setState({
      sourceErpSystems: sourceErpSystems.filter(erpSystem => erpSystem.id !== id)
    });

    $j.ajax({
      url,
      method: 'DELETE',
      data: { source_erp_system_id: id },
      success(data) {
        that.deleteSourceDataSystem(data, url, id);
        lineages.map((lineage) => {
          that.onLineageUpdate(lineage.id)
        });
      }
    });
  }

  setReportItemLineageTargetId(reportItemId, lineageTargetId) {
    if (!reportItemId || reportItemId == '') {
      return;
    }
    const reportItems = this.state.reportItems.map((ri) => {
      if (ri.id == reportItemId) {
        const newReportItem = Object.assign({}, ri, { lineageTargetId });
        return newReportItem;
      }
      return ri;
    });
    this.setState({
      reportItems,
    });
  }

  updateReportItems(lineageTargetId, reportItem) {
    const reportItems = this.state.reportItems.map((ri) => {
      if (ri.lineage_target_id == lineageTargetId || ri.lineageTargetId == lineageTargetId) {
        const newReportItem = Object.assign({}, ri);
        delete newReportItem.lineage_target_id;
        delete newReportItem.lineageTargetId;
        return newReportItem;
      } else if (ri.id == reportItem.id) {
        return reportItem;
      }
      return ri;
    });
    const ri = reportItems.find(item => (item.id == reportItem.id));
    if (!ri) {
      reportItems.push(reportItem);
    }
    this.setState({
      ...this.state,
      reportItems,
    });
  }

  render() {
    return <DataMappingControlComponent {...this.state} />;
  }
}
