import { render } from '@testing-library/react';
import ImportFileForm from './ImportFileForm';

describe('<ImportFileForm />', () => {
  it('should render correct import file form', async () => {
    const component = exercise();
    const previewButton = await component.getPreviewButton();
    const chooseFileForm = await component.getChooseFileForm();
    const fileField = await component.getFileField();
    const additionalButton = await component.getAdditionalButton();
    const cancelButton = await component.getCancelButton();
    expect(previewButton).toBeVisible();
    expect(chooseFileForm).toBeVisible();
    expect(fileField).toBeVisible();
    expect(additionalButton).toBeVisible();
    expect(cancelButton).toBeVisible();
  });
});

const exercise = () => {
  const component = render(<ImportFileForm
    modelType="specification"
    userId={2}
    institutionId={0}
    importUrl="test/url"
    previewButton="previewButton"
    cancelUrl='cancelUrl/'
    additionalButton={{link: 'btnLink', name: 'AdditionalButton'}}
  />);

  return {
    ...component,

    async getPreviewButton() {
      return component.findByText('previewButton');
    },

    async getChooseFileForm() {
      return component.findByRole('form');
    },

    async getFileField() {
      return component.findByRole('input');
    },

    async getAdditionalButton() {
      return component.findByText('AdditionalButton')
    },

    async getCancelButton() {
      return component.findByText('Cancel')
    }
  };
};
