class DataItemForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleRelatedObjectChange = this.handleRelatedObjectChange.bind(this);
    this.removeRelatedObject = this.removeRelatedObject.bind(this);
    this.changeRelatedObject = this.changeRelatedObject.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.handleObjectLabelChange = this.handleObjectLabelChange.bind(this);
    this.createDataItem = this.createDataItem.bind(this);
    this.updateDataItem = this.updateDataItem.bind(this);
    this.findTermById = this.findTermById.bind(this);
    this.clearRelatedColumn = this.clearRelatedColumn.bind(this);
    this.handleTermSelectClean = this.handleTermSelectClean.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      ...props
    };
  }

  componentDidMount() {
    this.termsSelectBox && this.termsSelectBox.addEventListener('select-changed', this.handleTermsSelectChange);
  }

  componentDidUpdate(_prevProps, _prevState) {
    const { embedded } = this.props;
    if (!embedded) {
      let opened;
      try {
        opened = $j(this.el).hasClass('ui-dialog-content') && $j(this.el).dialog('isOpen');
      } catch (e) {
        console.errors(e);
      }

      if (!opened) {
        $j(this.el).dialog({
          modal: true,
          resizable: false,
          height: 350,
          width: 550,
          close: this.props.onCancel
        });
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    const { embedded } = this.props;
    if (!embedded && !nextProps.reportItem)
      $j(this.el).dialog('destroy');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reportItem) {
      this.setState({
        report_item: this.props.reportItem || {},
        errors: []
      });
    }
  }

  handleSelectChange(selectedOption) {
    const value = (selectedOption && selectedOption.value) || '';
    const fakeEvent = {
      target: {
        value
      }
    };
    if (selectedOption)
      this.handleRelatedObjectChange(fakeEvent, 'Term');
    else
      this.handleTermSelectClean(fakeEvent);
  }

  handleTermSelectClean(e) {
    const inputText = e.target.value;
    if (inputText === null || inputText.length === 0)
      this.handleRelatedObjectChange(e, 'Term');
  }

  handleRelatedObjectChange(e, type) {
    if (e.target.value === '')
      this.removeRelatedObject();
    else {
      const term = this.findTermById(e.target.value);
      const reportItem = Object.assign({}, this.props.reportItem);
      reportItem.term_id = term.id;
      reportItem.object_label = term.name;
      reportItem.report_version_id = this.props.reportVersion.id;
      this.props.onDataItemModified(reportItem);
    }
  }

  removeRelatedObject(e) {
    const emptyTerm = {
      term: '',
      termId: null,
      term_id: null,
      dataObjectType: '',
      dataObject: {}
    };
    const reportItem = Object.assign({}, this.props.reportItem, emptyTerm);
    this.props.onDataItemModified(reportItem);
  }

  changeRelatedObject(type, object) {
    const reportItem = {
      ...this.props.reportItem,
      data_model_object_type: type,
      data_model_object: object,
      data_model_object_id: object.id,
      report_version_id: this.props.reportVersion.id
    };
    if (!reportItem.term_id) {
      const primaryDefinition = this.getPrimaryDefinition(object);
      if (primaryDefinition) {
        reportItem.term_id = primaryDefinition.id;
        reportItem.term = primaryDefinition;
      }
    }
    if (!reportItem.objectLabel && !reportItem.object_label)
      reportItem.object_label = object.name;

    this.props.onDataItemModified(reportItem);
  }

  getPrimaryDefinition(dataModelObject) {
    const technicalRelationships = dataModelObject.technical_relationships;
    const { terms } = this.props;
    if (!_.isEmpty(technicalRelationships)) {
      const tr = technicalRelationships[0];
      if (tr.dcb_object_type === 'Term' && tr.dcb_object_id)
        return terms.find(term => term.id === tr.dcb_object_id);
    }
    return null;
  }

  getSuggestionValue(suggestion) {
    if (suggestion)
      this.changeRelatedObject('DataModelColumn', suggestion);

    return suggestion && `DataModelColumn: ${suggestion.name}`;
  }

  handleObjectLabelChange(e) {
    const reportItem = {
      ...this.props.reportItem,
      object_label: e.target.value
    };
    this.props.onDataItemModified(reportItem);
  }

  findTermById(termId) {
    return this.props.terms.find(term => term.id == termId);
  }

  clearRelatedColumn() {
    const reportItem = Object.assign({}, this.props.reportItem);
    const termId = reportItem.term_id;
    if (termId) {
      const term = this.findTermById(termId);
      if (term)
        reportItem.term = term;
      else
        reportItem.term = null;
    }
    reportItem.data_model_object_type = null;
    reportItem.data_model_object = null;
    reportItem.data_model_object_id = null;
    this.props.onDataItemModified(reportItem);
  }

  handleErpSystemChange(e) {
    const reportItem = Object.assign({}, this.props.reportItem);
    reportItem.erp_system_id = e.target.value;
    reportItem.erpSystemId = e.target.value;
    this.props.onDataItemModified(reportItem);
  }

  getControllerUrl() {
    let url = window.location.href;
    if (url.indexOf('/versions') === -1) {
      const tabName = url.split('/').last();
      url = `${url.substr(0, url.indexOf(`/${tabName}`))}/versions/${this.props.reportVersion.id}/report_items`;
    } else
      url = 'report_items';

    return url;
  }

  createDataItem(e) {
    e.preventDefault();
    const reportItem = {
      ...this.props.reportItem
    };

    $j.ajax({
      url: this.getControllerUrl(),
      method: 'POST',
      data: {
        ...reportItem
      },
      success: (data) => {
        if (data.termId) {
          const term = this.findTermById(data.termId);
          if (term)
            data.term = term;
        }
        this.props.onDataItemCreate && this.props.onDataItemCreate(data);
        this.props.onCancel && this.props.onCancel();
        this.showDeleteAllButton();
        this.showExportButton();
      }
    }).error((xhr) => {
      if (xhr.responseJSON.errors)
        this.setState({ errors: xhr.responseJSON.errors });
      else
        location.reload();
    });
  }

  updateDataItem(e) {
    e.preventDefault();
    let differentModelSourceError = this.state.errors.filter((error) => error.includes('Either change the source data system or select a different data model object from the source data system'));
    let withoutSourceValidation = differentModelSourceError.length > 0;
    const report_item = {
      ...this.props.reportItem
    };
    $j.ajax({
      url: `${this.getControllerUrl()}/${report_item.id}`,
      method: 'PATCH',
      dataType: 'JSON',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        ...report_item,
        without_source_validation: withoutSourceValidation
      }),
      success: (data) => {
        if (data.termId) {
          const term = this.findTermById(data.termId);
          if (term)
            data.term = term;
        }
        this.props.onDataItemUpdated && this.props.onDataItemUpdated(data);
        this.props.onCancel && this.props.onCancel();
      }
    }).error((xhr) => {
      if (xhr.responseJSON.errors)
        this.setState({ errors: xhr.responseJSON.errors });
      else
        location.reload();
    });
  }

  showDeleteAllButton() {
    let deleteAllItemsBtn = $j('.delete-lineages-container');
    if(deleteAllItemsBtn.hasClass('hide')) {
      deleteAllItemsBtn.removeClass('hide');
    }
  }

  showExportButton() {
    let exportBtn = $j('.export-lineages');
    if(exportBtn.hasClass('hide')) {
      exportBtn.removeClass('hide');
    }
  }

  render() {
    if (!this.props.reportItem)
      return null;

    const { embedded } = this.props;

    const terms = this.props.uniqueTerms;
    const { reportItem } = this.props;

    const label = reportItem.object_label || reportItem.objectLabel || '';
    const labelClass = '';
    const dataModelObject = reportItem.data_model_object || reportItem.dataModelObject || null;
    const dataModelObjectType = reportItem.data_model_object_type || reportItem.dataModelObjectType || '';
    const dataModelValue = (dataModelObject && `${dataModelObjectType}: ${dataModelObject.name}`) || '';
    const termDisabled = false;
    const termValue = reportItem.term_id || reportItem.termId || '';
    const erpSystemId = this.props.targetErpSystem &&
      (this.props.targetErpSystem.id || this.props.targetErpSystem);
    const sourceErpSystemId = reportItem.erp_system_id || reportItem.erpSystemId;
    const controls = this.props.reportItem && this.props.reportItem.id
      ? (
        <input
          className="f-r"
          type="submit"
          onClick={this.updateDataItem}
          value="Update"
        />
        ) : (
          <input
            className="f-r"
            type="submit"
            onClick={this.createDataItem}
            value="Save"
          />
        );
    const options = terms.map(trm => ({ value: trm.id, label: trm.name }));
    const selectedOption = options.filter(option => option.value === termValue);

    return (
      <div
        title={this.props.reportItem && this.props.reportItem.id ? 'Edit an Item' : 'Add an Item'}
        ref={el => this.el = el}
      >
        {!embedded && <Errors errors={this.state.errors} />}

        <label key="label-definition">
          Definition: <HelpPoint helpPoint={this.props.helpPoints.point1} />
        </label>
        <span className="term-select-box">
          <OptimizedSelect
            options={options}
            value={selectedOption}
            onChange={this.handleSelectChange}
            isDisabled={termDisabled}
          />
        </span>
        <br />
        { this.props.reportTemplate?.code === 'ETL' &&
        ([
          <label key="label-target-data-system-object">
            Target Data System Object: <HelpPoint helpPoint={this.props.helpPoints.point2} />
          </label>,
          <AutosuggestColumnComponent
            key="autosuggest-data-system-object"
            placeholder="Type a data system column"
            value={dataModelValue}
            erpSystemId={erpSystemId}
            getSuggestionValue={this.getSuggestionValue || dataModelValue}
            clearRelatedColumn={this.clearRelatedColumn}
            dependsOnTargetErpSystem="true"
          />
        ])
        }
        <br />
        <label key="label-label">
          Label: <HelpPoint helpPoint={this.props.helpPoints.point3} />
        </label>
        <input
          type="text"
          value={label}
          className={labelClass}
          onChange={this.handleObjectLabelChange}
        /><br />
        <br />
        <label>Source Data System: <HelpPoint helpPoint={this.props.helpPoints.point4} /></label>
        <select
          id="erp-system-select"
          value={sourceErpSystemId || ''}
          onChange={e => this.handleErpSystemChange(e)}
        >
          <option value="">
            -Choose Data System-
          </option>
          {
            this.props.sourceErpSystems
              .findAll(erp => (erp.childSystems && erp.childSystems.length > 0))
              .map(erp => (
                <optgroup
                  key={erp.id}
                  value={erp.id}
                  label={erp.name}
                >
                  {erp.childSystems.map(childErp => (
                    <option
                      key={childErp.id}
                      value={childErp.id}
                    >
                      {childErp.name}
                    </option>
                  ))}
                </optgroup>
              ))}
          { this.props.sourceErpSystems.findAll(erp => (!erp.groupFlag)).map(erp => (
            <option
              key={erp.id}
              value={erp.id}
              label={erp.name}
            >
              {erp.name}
            </option>
          ))}
        </select>
        {!embedded && controls}
      </div>
    );
  }
}

export default DataItemForm;
