import React, { useState } from 'react';

const ImportFileForm = (props) => {
  const {
    modelType,
    userId,
    institutionId,
    importUrl,
    previewButton,
    reportVersionId,
    reportId,
    cancelUrl,
    additionalButton,
    previewBtnIsHidden,
  } = props;
  const [buttonIsVisible, setButtonIsVisible] = useState(!previewBtnIsHidden);

  const modelTypes = {
    specification: 'Report',
    definition: 'Term',
    lineage: 'Lineage',
    data_items: 'DataItems'
  };

  return (
    <div className="span-24 import selectFileForm">
      <form className="new_import_file" encType="multipart/form-data" id={`new_import_file_${modelType}`} role="form" acceptCharset="UTF-8" action={importUrl} method="post">
        <input type="hidden" name="authenticity_token" />
        <input type="file" className={buttonIsVisible ? '' : 'file-input'} aria-label="import file" name="import_file[file]" id="import_file_file" role="input" onChange={()=> setButtonIsVisible(true)}/>
        <input value={userId} type="hidden" name="import_file[user_id]" id="import_file_user_id" />
        <input value={institutionId} type="hidden" name="import_file[institution_id]" id="import_file_institution_id" />
        <input value={modelTypes[modelType]} type="hidden" name="import_file[model_type]" id="import_file_model_type" />
        {reportVersionId && <input value={reportVersionId} type="hidden" name="import_file[report_version_id]" id="import_file_report_version_id" />}
        {reportId && <input value={reportId} type="hidden" name="import_file[report_id]" id="import_file_report_id" />}
        {buttonIsVisible && <input type="submit" name="commit" value={previewButton} />}
        {additionalButton && <a type="button" className='margin-left-10px' href={additionalButton.link}>{additionalButton.name}</a>}
        {cancelUrl && <span className='cancel-link-wrapper'> or <a href={cancelUrl}>Cancel</a></span>}
      </form>
    </div>
  );
};

export default ImportFileForm;
